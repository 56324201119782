<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-12 15:05:46 
 * @LastEditTime: 2022-12-08 12:00:09
 * @LastEditors: cyy
 * @Description: 新建优惠券
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\newCoupon.vue
-->
<template>
  <div class="newCoupon">
    <div class="tip">
      创建成功后，只能修改发放量和商品范围，且发放量和商品范围只能增加，不能减少
    </div>

    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="140px"
      class="ruleFormclass"
      label-position="left"
    >
      <div class="title">基本信息</div>
      <el-form-item label="优惠券名称" :required="true">
        <el-input
          :disabled="isEdit"
          v-model="ruleForm.name"
          @blur="ruleForm.name = ruleForm.name.replace(/^\s+|\s+$/gm, '')"
          style="width:550px"
          size="medium"
          placeholder="输入优惠券名称"
          show-word-limit
          maxlength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="优惠券类型" :required="true">
        <div class="flex-center">
          <el-radio-group v-model="ruleForm.type" :disabled="isEdit">
            <el-radio label="1">
              通用券
              <helpIcon
                style="margin-left:6px;"
                prompt="全部用户可领让利用户，提高成交率"
              ></helpIcon>
            </el-radio>
            <el-radio label="2">
              专属券
              <helpIcon
                style="margin-left:6px;"
                prompt="指定用户身份可领精准转化，打造专属感"
              ></helpIcon>
            </el-radio>
          </el-radio-group>
          <div class="info" style="margin-left:60px">提示：创建后不可修改</div>
        </div>
      </el-form-item>

      <el-form-item label="指定身份主动领取" v-if="ruleForm.type == 2">
        <el-radio-group v-model="ruleForm.identity_type">
          <el-radio label="1">
            学员分组
          </el-radio>
          <el-radio label="2">
            学员标签
          </el-radio>
        </el-radio-group>
        <div class="student_group">
          <div class="select_ground">
            <el-button
              type="primary"
              size="medium"
              @click="
                ruleForm.identity_type == 1
                  ? (groupstatus = true)
                  : (tagstatus = true)
              "
            >
              {{
                ruleForm.identity_type == 1
                  ? ' + 选择学员分组'
                  : '+ 选择学员标签'
              }}
            </el-button>
            <div class="number" v-if="ruleForm.identity_type == 1">
              {{ groupCount }}/5
            </div>
            <div class="number" v-else>{{ tagCount }}/5</div>
          </div>
          <div
            class="groups_name"
            v-if="ruleForm.identity_type == 1 && groupCount > 0"
          >
            <div class="item" v-for="(i, index) in hadselectList" :key="i.gid">
              {{ i.gname }}
              <i class="el-icon-close" @click="delGroup(index)"></i>
            </div>
          </div>

          <div
            class="groups_name"
            v-else-if="ruleForm.identity_type == 2 && tagCount > 0"
          >
            <div
              class="item"
              v-for="(i, index) in tagHadselectList"
              :key="i.tag_id"
            >
              {{ i.tag_name }}
              <i class="el-icon-close" @click="delTag(index)"></i>
            </div>
          </div>
          <div class="stu_num">
            预计可主动领取用户为
            {{ ruleForm.identity_type == 1 ? groupNumber : tagNumber }} 人
          </div>
        </div>
      </el-form-item>

      <el-form-item label="优惠方式" :required="true">
        <el-radio-group v-model="ruleForm.pre_way" :disabled="isEdit">
          <el-radio label="1">
            满减
          </el-radio>
          <el-radio label="2">
            折扣
          </el-radio>
        </el-radio-group>
        <div class="pre_way" v-if="ruleForm.pre_way == 1">
          减
          <el-input-number
            :disabled="isEdit"
            class="ml10 mr10"
            v-model="ruleForm.cou_price"
            controls-position="right"
            size="medium"
            placeholder="输入金额"
            style="width:156px"
            :step="1"
            :min="0.01"
            :max="999999999.99"
            :precision="2"
          ></el-input-number>
          元
        </div>
        <div class="pre_way" v-else>
          打
          <el-input-number
            :disabled="isEdit"
            class="ml10 mr10"
            v-model="ruleForm.discount"
            controls-position="right"
            size="medium"
            placeholder="输入折扣"
            style="width:136px"
            :step="1"
            :precision="1"
            :min="0.1"
            :max="9.9"
          ></el-input-number>

          折
        </div>
      </el-form-item>

      <el-form-item label="使用门槛" :required="true">
        <el-radio-group v-model="ruleForm.thresholdtype" :disabled="isEdit">
          <el-radio label="1">无门槛</el-radio>
          <el-radio label="2">
            <div class="flex-center" style="color:#333333">
              满
              <el-input-number
                :disabled="ruleForm.thresholdtype == 1 || isEdit"
                class="ml10 mr10"
                v-model="ruleForm.threshold"
                controls-position="right"
                size="medium"
                placeholder="输入金额"
                style="width:200px"
                :step="1"
                :min="0.01"
                :max="99999999999999.99"
                :precision="2"
              ></el-input-number>
              元使用
            </div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="叠加使用" class="pl10">
        <div class="flex-center">
          <el-checkbox
            :disabled="isEdit"
            v-model="ruleForm.is_stackable"
            true-label="1"
            false-label="2"
          >
            <div class="info">
              提示：勾选后允许和其他可叠加优惠券叠加使用
            </div>
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item label="发放数量" :required="true">
        <el-input
          class="mr10"
          size="medium"
          v-model="ruleForm.count_all"
          placeholder="输入数量，最小1张，最大100万张"
          style="width:263px"
          @input="
            ruleForm.count_all = ruleForm.count_all.replace(
              /^0(0+)|[^\d]+/g,
              ''
            )
          "
        ></el-input>
        张
      </el-form-item>

      <el-form-item label="适用商品" :required="true">
        <el-radio-group
          v-model="ruleForm.apply_type"
          @change="applyType"
          :disabled="isEdit"
        >
          <el-radio label="1">
            全部商品可用
          </el-radio>
          <el-radio label="2">
            指定商品可用
          </el-radio>
          <el-radio label="3">
            指定商品不可用
          </el-radio>
        </el-radio-group>
        <div class="commodity" v-if="ruleForm.apply_type != 1">
          <el-button
            type="primary"
            class="mb20"
            size="medium"
            style="width:120px"
            @click="addgood(1)"
          >
            + 添加商品
          </el-button>
          <div class="item_contain">
            <div
              class="item"
              v-for="(i, index) in ruleForm.goodsList"
              :key="i.id"
            >
              <div class="tu">
                <img :src="i.cover_img || i.photo || i.img_url" alt="" />
              </div>
              <div class="right">
                <div class="itemtitle">{{ i.name }}</div>
                <div class="msg">
                  <div class="flex-center">
                    <template v-if="i.price">
                      <div class="money">￥{{ i.price }}</div>
                      <div
                        class="cross_money"
                        v-if="
                          (i.crossed_price && i.crossed_price > 0) ||
                            (i.costprice && i.costprice > 0) ||
                            (i.cost_price && i.cost_price > 0)
                        "
                      >
                        ￥ {{ i.crossed_price || i.costprice || i.cost_price }}
                      </div>
                    </template>
                    <template v-if="i.member_id">
                      <div class="type" v-if="'is_show' in i">
                        <!-- 1展示 2不展示 -->
                        <img
                          src="~@ass/img/1.3.9.4/icon_dg.png"
                          alt=""
                          v-if="i.is_show == 1"
                        />
                        <img
                          src="~@ass/img/1.3.9.4/icon_cw.png"
                          alt=""
                          v-else
                        />

                        <span v-if="i.is_show == 1">展示到网校</span>
                        <span v-else>不展示到网校</span>

                        <!-- 1已上架  2已下架   -->
                        <img
                          v-if="i.status == 1"
                          class="ml20"
                          src="~@ass/img/1.3.9.4/icon_dg.png"
                          alt=""
                        />
                        <img
                          v-else
                          class="ml20"
                          src="~@ass/img/1.3.9.4/icon_cw.png"
                          alt=""
                        />

                        <span v-if="i.status == 1">已启用</span>
                        <span v-else>已停用</span>
                      </div>
                    </template>
                    <div class="course_type">
                      {{ i.good_type | getGoodtext }}
                    </div>
                  </div>

                  <el-button
                    type="text"
                    @click="delcourse(index, 1)"
                    class="  fs14  "
                    size="mini"
                    :style="
                      i.member_id
                        ? ''
                        : ' margin-left: 76px;overflow-x: hidden;'
                    "
                  >
                    移除
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="使用时间" :required="true">
        <el-radio-group v-model="ruleForm.time_type" :disabled="isEdit">
          <p>
            <el-radio label="1">
              自定义
              <el-date-picker
                :disabled="ruleForm.time_type != 1 || isEdit"
                class="ml10"
                v-model="ruleForm.value1"
                type="datetimerange"
                @change="customtimepick"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                size="medium"
                value-format="timestamp"
                style="width:356px"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
              天内可用
            </el-radio>
          </p>

          <p class="mb30 mt30">
            <el-radio label="2">
              领取当日起
              <el-input-number
                :disabled="ruleForm.time_type != 2 || isEdit"
                class="ml10  "
                v-model="ruleForm.days1"
                controls-position="right"
                size="medium"
                placeholder="输入天数"
                style="width:136px"
                :step="1"
                :min="1"
                :max="2000"
                :step-strictly="true"
                :precision="0"
              ></el-input-number>
              天内可用
            </el-radio>
          </p>
          <p>
            <el-radio label="3">
              领取次日起
              <el-input-number
                :disabled="ruleForm.time_type != 3 || isEdit"
                class="ml10  "
                v-model="ruleForm.days2"
                controls-position="right"
                size="medium"
                placeholder="输入天数"
                style="width:136px"
                :step="1"
                :min="1"
                :max="2000"
                :step-strictly="true"
                :precision="0"
              ></el-input-number>
              天内可用
            </el-radio>
          </p>
        </el-radio-group>
      </el-form-item>

      <div class="title">领用规则</div>
      <el-form-item label="领取次数" :required="true">
        <el-radio-group v-model="ruleForm.receive_num_type" :disabled="isEdit">
          <el-radio label="1">
            不限次数
          </el-radio>
          <el-radio label="2">
            <div style="color:#333333">
              每人限领
              <el-input-number
                :disabled="ruleForm.receive_num_type != 2 || isEdit"
                class="ml10 mr10 "
                v-model="ruleForm.receive_num"
                controls-position="right"
                size="medium"
                placeholder="输入次数"
                style="width:136px"
                :step="1"
                :min="1"
                :max="10"
                :step-strictly="true"
                :precision="0"
              ></el-input-number>
              次
            </div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="营销活动叠加限制" class="pl10">
        <div class="flex-center">
          <el-checkbox
            :disabled="isEdit"
            v-model="ruleForm.activity_overlay"
            true-label="1"
            false-label="2"
          >
            <div class="info">
              提示：勾选后，优惠券仅原价购买商品时可用，不可与其他营销活动叠加使用，比如：裂变海报、拼团、优惠码（旧版优惠券）
            </div>
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item label="公开设置" class="pl10">
        <div class="flex-center">
          <el-checkbox
            v-model="ruleForm.is_open"
            true-label="1"
            false-label="2"
          >
            <div class="info">
              提示：勾选后，优惠券在商品详情页展示，用户可领取
            </div>
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item label="使用说明" class="pl10">
        <el-input
          type="textarea"
          placeholder="输入使用说明"
          v-model="ruleForm.instructions"
          rows="7"
          style="width:500px"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>

      <div class="title">个性设置</div>
      <el-form-item label="余量展示设置" class="pl10" :required="true">
        <el-radio-group v-model="ruleForm.unclaimed_show">
          <el-radio label="1">
            展示
          </el-radio>
          <el-radio label="2">
            隐藏
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="推荐商品" class="pl10">
        <el-radio-group
          v-model="ruleForm.recommend_type"
          @change="recommendType"
        >
          <el-radio label="1">
            最新上架
          </el-radio>
          <el-radio label="2">
            自定义商品（最多可展示10个商品）
          </el-radio>
        </el-radio-group>
        <div class="commodity" v-if="ruleForm.recommend_type == 2">
          <el-button
            type="primary"
            class="mb20"
            size="medium"
            style="width:120px"
            @click="addgood(2)"
          >
            + 添加商品
          </el-button>
          <div class="item_contain">
            <transition-group
              class="ul"
              tag="ul"
              name="flip-list"
              v-if="ruleForm.recommendList.length > 0"
            >
              <div
                class="item"
                v-for="(i, index) in ruleForm.recommendList"
                :key="i.id"
              >
                <div class="tu">
                  <img :src="i.cover_img || i.photo || i.img_url" alt="" />
                </div>
                <div class="right">
                  <div class="itemtitle">{{ i.name }}</div>
                  <div class="msg">
                    <div class="flex-center">
                      <template v-if="i.price">
                        <div class="money">￥{{ i.price }}</div>
                        <div
                          class="cross_money"
                          v-if="
                            (i.crossed_price && i.crossed_price > 0) ||
                              (i.costprice && i.costprice > 0) ||
                              (i.cost_price && i.cost_price > 0)
                          "
                        >
                          ￥
                          {{ i.crossed_price || i.costprice || i.cost_price }}
                        </div>
                      </template>
                      <template v-if="i.member_id">
                        <div class="type" v-if="'is_show' in i">
                          <!-- 1展示 2不展示 -->
                          <img
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                            v-if="i.is_show == 1"
                          />
                          <img
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                            v-else
                          />

                          <span v-if="i.is_show == 1">展示到网校</span>
                          <span v-else>不展示到网校</span>

                          <!-- 1已上架  2已下架   -->
                          <img
                            v-if="i.status == 1"
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                          />

                          <span v-if="i.status == 1">已启用</span>
                          <span v-else>已停用</span>
                        </div>
                      </template>
                      <div class="course_type">
                        {{ i.good_type | getGoodtext }}
                      </div>
                    </div>
                    <div class="btns">
                      <el-button
                        :disabled="index == 0"
                        type="text"
                        @click="upDownMove(index, index - 1)"
                        class="  fs14  "
                        size="mini"
                        :style="i.member_id ? '' : ' margin-left: 76px;'"
                      >
                        上移
                      </el-button>
                      <el-divider direction="vertical"></el-divider>
                      <el-button
                        type="text"
                        @click="upDownMove(index, index + 1)"
                        class="  fs14  "
                        size="mini"
                        :disabled="index == ruleForm.recommendList.length - 1"
                      >
                        下移
                      </el-button>
                      <el-divider direction="vertical"></el-divider>
                      <el-button
                        type="text"
                        @click="delcourse(index, 2)"
                        class="  fs14  "
                        size="mini"
                      >
                        移除
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div class="submit">
      <el-button size="medium" class="mr10" @click="goback" style="width:100px">
        取消
      </el-button>
      <el-button
        type="primary"
        size="medium"
        @click="submit"
        style="width:100px"
      >
        保存
      </el-button>
    </div>

    <!-- 分组选择 -->
    <group-list
      v-if="groupstatus"
      :limit="tagGroupLint"
      @selectionChange="selectionChange"
      :hadselectList="hadselectList"
      :dialogstatus.sync="groupstatus"
    ></group-list>

    <!-- 选择标签 -->
    <tag-list
      v-if="tagstatus"
      :limit="tagGroupLint"
      @selectionChange="tagSelectionChange"
      :hadselectList="tagHadselectList"
      :dialogstatus.sync="tagstatus"
    ></tag-list>

    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="networkschoolSelectStatus = false"
      :goodList="goodListselect"
      :recommendList="recommendList"
      :goods_class="1"
      :limit="goodtype == 1 ? 100 : 10"
      :goodtype="goodtype"
      @singleselectcontentval="singleselectcontentval"
      title="添加商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="14"
      :goodlistType="true"
    ></networkschoolSelect>

    <!-- 选择自定商品 -->
    <available
      v-if="availableStatus"
      @availableval="availableval"
      :dialogstatus.sync="availableStatus"
      :availableList="availableList"
      :availableSelect="availableSelect"
      :limit="10"
    ></available>
  </div>
</template>

<script>
import available from './components/available'
import groupList from '@cm/base/groupList'
import tagList from './components/tagList'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'newCoupon',

  components: {
    groupList,
    tagList,
    networkschoolSelect,
    available,
  },

  data() {
    return {
      recommendList: [],

      // 编辑时的发行数量限制
      countallLimit: 1,

      // 自定义推荐商品-已选的
      availableSelect: [],

      // 指定参数列表 -列表
      availableList: [],

      // 指定可用商品
      availableStatus: false,

      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },

      tagGroupLint: 5,

      // 是哪个位置选择商品
      goodtype: undefined,

      goodListselect: [],

      networkschoolSelectStatus: false,

      // 标签弹窗状态
      tagstatus: false,

      // 标签已选数据
      tagHadselectList: [],

      //已经选择的分组
      hadselectList: [],

      // 分组选择状态
      groupstatus: false,

      ruleForm: {
        recommend_type: '1',
        unclaimed_show: '1',
        instructions: '',
        is_open: '2',
        activity_overlay: '2',
        receive_num: '',
        receive_num_type: '1',
        days1: '',
        days2: '',
        days: undefined,
        end_time: '',
        start_time: '',
        value1: [],
        time_type: '1',
        name: '',
        type: '1',
        identity_type: '1',
        identity_ids: [],
        goodsList: [],
        recommendList: [],
        identity_goodList: [],
        pre_way: '1',
        discount: undefined,
        cou_price: undefined,
        thresholdtype: '1',
        threshold: undefined,
        is_stackable: '2',
        count_all: undefined,
        apply_type: '1',
      },
    }
  },

  mounted() {
    if (this.$route.query.id != 'new') {
      this.getdata()
      if (this.$route.query.type == 1) {
        this.$root.$children[0].childPageOptions[
          this.$root.$children[0].childPageOptions.length - 1
        ].name = '编辑优惠券'
      } else {
        this.$root.$children[0].childPageOptions[
          this.$root.$children[0].childPageOptions.length - 1
        ].name = '新建优惠券'
      }
    }
  },

  computed: {
    // 是否时编辑
    isEdit() {
      return this.coupon_id != 'new' && this.$route.query.type == 1
    },

    // 分组数据的长度
    groupCount() {
      return this.hadselectList.length
    },

    // 分组数据人数包含人数的总和
    groupNumber() {
      return this.hadselectList.reduce(
        (totol, item) => totol + Number(item.number),
        0
      )
    },

    coupon_id() {
      return this.$route.query.id
    },

    // 标签数据长度
    tagCount() {
      return this.tagHadselectList.length
    },

    // 标签数据人数包含人数的总和
    tagNumber() {
      return this.tagHadselectList.reduce(
        (totol, item) => totol + Number(item.number),
        0
      )
    },

    // 分组的id合集f
    groupIds() {
      return this.hadselectList.map(item => item.gid)
    },

    // 标签id合计
    tagIds() {
      return this.tagHadselectList.map(item => item.tag_id)
    },
  },

  filters: {
    getgoodType(val) {
      let endVal = null
      if (val.course_id) {
        switch (Number(val.course_type)) {
          case 1:
            endVal = 1
            break
          case 2:
            endVal = 2
            break
          case 3:
            endVal = 3
            break
          default:
            endVal = 4
            break
        }
      } else if (val.open_class_id) {
        endVal = 5
      } else if (val.ed_id) {
        endVal = 6
      } else if (val.user_id) {
        endVal = 7
      } else if (val.live_goods_id) {
        endVal = 8
      } else if (val.graphic_id) {
        endVal = 9
      }
      return endVal
    },

    getGoodtext(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '电子资料'
          break
        case 7:
          text = '会员'
          break
        case 8:
          text = '知识商品'
          break
        case 9:
          text = '图文'
          break
      }

      return text
    },
  },

  methods: {
    goback() {
      this.$router.push({ path: '/coupon' })
    },

    recommendType() {
      // this.ruleForm.recommendList = []
    },

    applyType() {
      this.ruleForm.recommendList = []
    },

    availableval(val) {
      this.availableStatus = false
      this.ruleForm.recommendList = val
      this.availableSelect = this.ruleForm.recommendList
    },

    // 获取编辑数据
    async getdata() {
      const { data } = await this.$http({
        url: `/coupon/setCoupon?coupon_id=${this.coupon_id}`,
      })

      this.countallLimit = Number(data.count_all)

      if (data.time_type == 2) {
        this.ruleForm.days1 = data.days
      } else if (data.time_type == 3) {
        this.ruleForm.days2 = data.days
      } else {
        this.ruleForm.value1 = [data.start_time * 1000, data.end_time * 1000]
        this.ruleForm.days1 = ''
        this.ruleForm.days2 = ''
      }

      if (data.identity_type == 1) {
        this.hadselectList = data.identity_list
      } else {
        this.tagHadselectList = data.identity_list
      }

      if (data.pre_way == 1) {
        data.discount = undefined
      } else {
        data.cou_price = undefined
      }

      if (data.thresholdType == 1) {
        data.threshold = undefined
      }

      if (data.receive_num <= 0) {
        this.ruleForm.receive_num_type = '1'
      } else {
        this.ruleForm.receive_num_type = '2'
      }

      if (data.recommend_type == 1) {
        this.recommendList = []
      }

      this.ruleForm = _.assign({}, this.ruleForm, data)
    },

    // 上下移动
    upDownMove(index, newIndex) {
      const oldData = this.ruleForm.recommendList[newIndex]
      this.ruleForm.recommendList.splice(
        newIndex,
        1,
        this.ruleForm.recommendList[index]
      )
      this.ruleForm.recommendList.splice(index, 1, oldData)
    },

    // 时间
    customtimepick(val) {
      val = val || [0, 0]
      this.ruleForm.start_time = val[0] / 1000
      this.ruleForm.end_time = val[1] / 1000
    },

    // 添加商品
    addgood(val) {
      this.goodtype = val
      if (val == 1) {
        this.goodListselect = _.assign([], this.ruleForm.goodsList)
        this.networkschoolSelectStatus = true
        this.recommendList = _.assign([], this.ruleForm.recommendList)
      } else {
        if (this.ruleForm.apply_type == 3 || this.ruleForm.apply_type == 1) {
          this.goodListselect = _.assign([], this.ruleForm.goodsList)
          this.recommendList = _.assign([], this.ruleForm.recommendList)
          // this.recommendList = this.ruleForm.recommendList
          this.networkschoolSelectStatus = true
        } else {
          this.availableList = _.assign([], this.ruleForm.goodsList)
          this.availableStatus = true
        }
      }
    },

    // 移除选择的商品
    delcourse(index, type) {
      if (type == 1) {
        this.ruleForm.goodsList.splice(index, 1)
      } else {
        this.ruleForm.recommendList.splice(index, 1)
      }
    },

    // 选择的商品
    singleselectcontentval(val) {
      val.map(item => {
        item.id = Math.random()
        item.good_type = this.$options.filters.getgoodType(item)
        return item
      })

      if (this.goodtype == 1) {
        this.ruleForm.goodsList.push(...val)
      } else {
        this.ruleForm.recommendList.push(...val)
      }
    },
    // 格式化
    // forNumber(val) {
    //   let val2 = this.toNumber(val)
    //   if (val2 && val2 == 0) {
    //     val2 = ''
    //   }

    //   return val2
    // },

    // 保存
    async submit() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        days:
          this.ruleForm.time_type == 2
            ? this.ruleForm.days1
            : this.ruleForm.days2,
        identity_ids:
          this.ruleForm.identity_type == 1 ? this.groupIds : this.tagIds,
      })

      if (!this.ruleForm.name) {
        this.$root.prompt({ msg: '请输入优惠券名称' })
        return
      }

      if (
        this.ruleForm.type == 2 &&
        ((this.groupIds.length == 0 && this.ruleForm.identity_type == 1) ||
          (this.tagIds.length == 0 && this.ruleForm.identity_type == 2))
      ) {
        this.$root.prompt({ msg: '请选择用户身份' })
        return
      }

      if (this.ruleForm.pre_way == 1 && !this.ruleForm.cou_price) {
        this.$root.prompt({ msg: '请输入优惠金额' })
        return
      }

      if (this.ruleForm.pre_way == 2 && !this.ruleForm.discount) {
        this.$root.prompt({ msg: '请输入折扣' })
        return
      }

      if (this.ruleForm.thresholdtype == 2 && !this.ruleForm.threshold) {
        this.$root.prompt({ msg: '请输入使用门槛金额' })
        return
      }

      if (!this.ruleForm.count_all) {
        this.$root.prompt({ msg: '请输入发放数量' })
        return
      }

      if (this.ruleForm.count_all > 1000000) {
        this.$root.prompt({ msg: '发行数量不能超过1,000,000张！' })
        return
      }

      if (
        this.ruleForm.apply_type != 1 &&
        this.ruleForm.goodsList.length == 0
      ) {
        this.$root.prompt({ msg: '请选择指定商品' })
        return
      }

      if (this.ruleForm.time_type == 1 && this.ruleForm.value1.length == 0) {
        this.$root.prompt({ msg: '请选择使用时间' })
        return
      }

      if (this.ruleForm.time_type != 1 && !this.ruleForm.days) {
        this.$root.prompt({ msg: '请输入天数' })
        return
      }

      if (this.ruleForm.receive_num_type == 2 && !this.ruleForm.receive_num) {
        this.$root.prompt({ msg: '请输入领取次数' })
        return
      }

      if (
        this.ruleForm.recommend_type == 2 &&
        this.ruleForm.recommendList.length == 0
      ) {
        this.$root.prompt({ msg: '请选择自定义商品' })
        return
      }

      if (this.$route.query.id == 'new' || this.$route.query.type == 2) {
        await this.$http({
          url: '/coupon/addCoupon',
          data: this.ruleForm,
          successMsg: true,
        })
      } else {
        await this.$http({
          url: `/coupon/setCoupon`,
          data: _.assign({}, this.ruleForm, { coupon_id: this.coupon_id }),
        })
      }

      this.$router.push('/coupon')
    },

    // 删除选择的分组
    delGroup(index) {
      this.hadselectList.splice(index, 1)
    },

    // 分组选择的内容
    selectionChange(val) {
      this.hadselectList = val
      this.groupstatus = false
    },

    // 删除选择的分组
    delTag(index) {
      this.tagHadselectList.splice(index, 1)
    },

    // 标签选择内容
    tagSelectionChange(val) {
      this.tagHadselectList = val
      this.tagstatus = false
    },
  },
}
</script>

<style lang="scss" scoped>
.newCoupon {
  background-color: #fff;
  padding: 20px;
  ::v-deep .el-input__count {
    color: #909399;
    background: #ffffff;
    position: absolute;
    font-size: 12px;
    bottom: -13px;
    right: -48px;
  }
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #333333;
  }
  ::v-deep .el-radio__label {
    display: inherit;
  }
  .flip-list-move {
    transition: transform 0.35s;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-divider {
      background-color: #0aa29b;
    }
  }
  ::v-deep .el-input-number__decrease {
    background-color: #fff;
  }
  ::v-deep .el-input-number__increase {
    background-color: #fff;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
  }
  .tip {
    width: 852px;
    height: 40px;
    background: #ffe8e8;
    border-radius: 2px;
    line-height: 40px;
    vertical-align: middle;
    border: 1px solid #ffd1d1;
    padding-left: 20px;
    font-size: 12px;
    color: #ab4337;
    margin-bottom: 30px;
  }
  .ruleFormclass {
    .commodity {
      .item_contain {
        max-height: 300px;
        overflow: auto;
        @extend %scroll;
        line-height: 1.5;
        .type {
          margin-left: 10px;
          align-items: center;
          display: flex;
          font-size: 12px;
          color: #666666;

          img {
            width: 8px;
            height: 7px;
            margin-right: 6px;
          }

          span {
            font-size: 12px;
            color: #666666;
          }
        }
        .item {
          margin-bottom: 20px;
          display: flex;
          .tu {
            width: 66px;
            height: 46px;
            background: #d8d8d8;
            margin-right: 14px;
            flex-shrink: 0;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .right {
            flex: 1;
            overflow: hidden;
            .itemtitle {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 16px;
              color: #333333;
            }
            .msg {
              display: flex;
              align-items: center;
              width: 500px;
              justify-content: space-between;

              .money {
                font-size: 14px;
                color: #ff3535;
              }
              .cross_money {
                font-size: 14px;
                color: #999999;
                margin: 0 30px 0 15px;
                text-decoration: line-through;
              }
              .course_type {
                font-size: 14px;
                color: #333333;
                margin-left: 30px;
              }

              .del {
              }
            }
          }
        }
      }
    }
    ::v-deep .el-input-number .el-input__inner {
      text-align: left !important;
    }

    ::v-deep .pre_way {
      display: flex;
      align-items: center;
    }
    .student_group {
      .select_ground {
        display: flex;
        align-items: center;
        .number {
          font-size: 14px;
          color: #333333;
          margin-left: 20px;
        }
      }
      .stu_num {
        font-size: 14px;
        color: #333333;
        line-height: 1.5;
        margin-top: 10px;
      }
      .groups_name {
        width: 500px;
        margin-top: 15px;
        line-height: normal;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 10px 0;
        box-sizing: border-box;
        background: #f5f5f5;
        .item {
          height: 30px;
          box-sizing: border-box;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 3px 10px;
          background: #ffffff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          .el-icon-close {
            padding: 5px;
            margin-left: 10px;
            cursor: pointer;
            color: #999999;
          }
        }
      }
    }
    .info {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
  }
  .submit {
    text-align: center;
    margin-top: 40px;
  }
}
</style>
