<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-12 16:29:42
 * @LastEditTime: 2022-07-20 15:31:46
 * @LastEditors: cyy
 * @Description: 选择分组
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\components\tagList.vue
-->

<template>
  <div class="tagList">
    <el-dialog
      title="选择学员标签"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="search">
          <div>
            <el-button type="primary" size="medium" class="mr10" @click="open">
              新建标签
            </el-button>
            <el-button type="text" size="medium" @click="toSearch">
              刷新
            </el-button>
          </div>
          <div>
            <el-input
              size="medium"
              v-model="input"
              placeholder="输入名称搜索"
              style="width:215px"
              clearable
              @keyup.enter.native="toSearch"
            ></el-input>
            <el-button
              type="primary"
              size="medium"
              @click="toSearch"
              class="ml10"
            >
              搜索
            </el-button>
          </div>
        </div>
        <pagination2
          :option="post"
          @complete="complete"
          url="/UserTag/getUserTag"
          ref="childDialogBox"
        >
          <template>
            <el-table
              @select="selectionChange"
              @row-click="rowClick"
              :row-key="getRowkey"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :empty-text="
                post.search
                  ? `&quot;${post.search}&quot; 关键词下无相关搜索`
                  : ' '
              "
              ref="multipleTable"
              :data="completelist"
            >
              <el-table-column
                :selectable="checkStatus"
                type="selection"
                width="45px"
                :reserve-selection="true"
              ></el-table-column>
              <el-table-column
                label="标签名称"
                prop="tag_name"
              ></el-table-column>
              <el-table-column
                label="包含人数(人)"
                prop="number"
              ></el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
      <span slot="footer" class="dialog-footer  ">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" size="medium" @click="submit">
          {{ count }}/{{ limit }} 确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'openingfunction',

  data() {
    return {
      input: '',
      count: 0,
      selectArr: [],
      completelist: [],
      post: {
        search: '',
        is_number: 1,
      },
    }
  },

  props: {
    // 已经选择的
    hadselectList: {
      type: Array,
      default: () => [],
    },

    // 选择个数限制
    limit: {
      type: Number,
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.selectArr = _.assign([], this.hadselectList)
  },

  methods: {
    open() {
      let url = this.$router.resolve({ path: '/manageStudent?type=2' })
      this.$store.dispatch('open', url.href)
    },

    // 搜索
    toSearch() {
      this.post.search = this.input
      this.$refs.childDialogBox.reset()
    },

    getRowkey(row) {
      return row.tag_id
    },

    complete(val) {
      this.completelist = val.data

      this.count = this.selectArr.length
      this.$nextTick(() => {
        this.toggleSelection()
      })
    },

    checkStatus(row) {
      // console.log(row, this.selectArr)
      if (row) {
        const state = this.selectArr.some(item => {
          if (item) {
            return item.tag_id == row.tag_id
          }
        })
        return this.count < this.limit || state
      }
    },

    toggleSelection() {
      if (this.hadselectList.length > 0) {
        this.toggleRowSelect = true
        let arr = this.selectArr.length > 0 ? this.selectArr : []

        this.completelist.forEach(row => {
          let selectRow = arr.find(item => item.tag_id === row.tag_id)
          this.$refs.multipleTable.toggleRowSelection(
            row,
            selectRow ? true : false
          )
        })

        this.toggleRowSelect = false
      }
    },

    rowClick(row) {
      this.nowRow = row
      const index = this.selectArr.findIndex(item => item.tag_id == row.tag_id)
      if (index > -1) {
        this.$refs.multipleTable.toggleRowSelection(row, false)
        this.count -= 1
        this.selectArr.splice(index, 1)
      } else {
        if (this.count == this.limit) {
          return
        }
        this.$refs.multipleTable.toggleRowSelection(row, true)
        this.count += 1
        this.selectArr.push(row)
      }
    },

    selectionChange(selection, row) {
      if (this.toggleRowSelect) {
        return
      }
      const index = selection.findIndex(item => item.tag_id == row.tag_id)

      if (index > -1) {
        this.count += 1
        this.selectArr.push(row)
      } else {
        this.count -= 1
        this.selectArr.splice(index, 1)
      }
    },

    submit() {
      this.$emit('selectionChange', this.selectArr)
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.tagList {
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
  ::v-deep .dialogVisible {
    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .el-table__body-wrapper {
      height: 210px;
      overflow: auto;
      @extend %scroll;
    }
    .el-table__header,
    .el-table__body,
    .el-table__footer {
      width: auto !important;
    }
    .el-dialog {
      height: 494px;
    }
    .el-dialog__body {
      padding: 10px 20px 0;
    }
    .el-dialog__footer {
      padding-top: 0;
    }
    .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    .el-dialog__header {
      padding-top: 14px;
    }
    .dialogVisible_bottom {
      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
