<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-18 19:17:41
 * @LastEditTime: 2022-09-05 10:26:25
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\components\available.vue
-->
<template>
  <div class="available">
    <el-dialog
      title="选择推荐商品"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-table
          :height="350"
          ref="multipleTable"
          @select="selectionChange"
          @row-click="rowClick"
          :row-key="getRowkey"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="
            availableList.slice(
              (currentPage1 - 1) * 5,
              (currentPage1 - 1) * 5 + 5
            )
          "
        >
          <el-table-column
            :reserve-selection="true"
            :selectable="checkStatus"
            label=""
            width="45px"
            type="selection"
          ></el-table-column>
          <el-table-column label="名称">
            <template slot-scope="{ row }">
              <div class="contain">
                <div class="tu">
                  <img
                    :src="row.cover_img || row.photo || row.img_url"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="itemtitle">{{ row.name }}</div>
                  <div class="msg">
                    <div class="flex-center">
                      <template v-if="row.price">
                        <div class="money">￥{{ row.price }}</div>
                        <div
                          class="cross_money"
                          v-if="
                            (row.crossed_price && row.crossed_price > 0) ||
                              (row.costprice && row.costprice > 0) ||
                              (row.cost_price && row.cost_price > 0)
                          "
                        >
                          ￥
                          {{
                            row.crossed_price || row.costprice || row.cost_price
                          }}
                        </div>
                      </template>
                      <template v-if="row.member_id">
                        <div class="type" v-if="'is_show' in row">
                          <!-- 1展示 2不展示 -->
                          <img
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                            v-if="row.is_show == 1"
                          />
                          <img
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                            v-else
                          />

                          <span v-if="row.is_show == 1">展示到网校</span>
                          <span v-else>不展示到网校</span>

                          <!-- 1已上架  2已下架   -->
                          <img
                            v-if="row.status == 1"
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                          />

                          <span v-if="row.status == 1">已启用</span>
                          <span v-else>已停用</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="{ row }">
              {{ row.good_type | getGoodtype }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          v-if="availableList.length > 0"
          :pager-count="5"
          :current-page.sync="currentPage1"
          :page-size="5"
          layout="prev, pager, next, jumper"
          :total="availableList.length"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer  ">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" size="medium" @click="submit">
          {{ count }}/{{ limit }} 确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'available',

  data() {
    return {
      selectArr: [],
      count: 0,
      currentPage1: 1,
    }
  },

  props: {
    limit: {
      type: Number,
      default: 0,
    },

    availableSelect: {
      type: Array,
      default: () => [],
    },

    availableList: {
      type: Array,
      default: () => [],
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  filters: {
    getGoodtype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '电子资料'
          break
        case 7:
          text = '会员'
          break
        case 8:
          text = '知识商品'
          break
        case 9:
          text = '图文'
          break
      }
      return text
    },
  },

  created() {
    this.selectArr = _.assign([], this.availableSelect)
    this.$nextTick(() => {
      this.toggleSelection()
    })
  },

  methods: {
    toggleSelection() {
      this.count = this.availableSelect.length
      if (this.availableSelect.length > 0) {
        this.toggleRowSelect = true
        let arr = this.selectArr.length > 0 ? this.selectArr : []

        this.availableList.forEach(row => {
          let selectRow = arr.find(item => item.id === row.id)
          this.$refs.multipleTable.toggleRowSelection(
            row,
            selectRow ? true : false
          )
        })

        this.toggleRowSelect = false
      }
    },

    checkStatus(row) {
      if (row) {
        const state = this.selectArr.some(item => {
          if (item) {
            return item.id == row.id
          }
        })
        return this.count < this.limit || state
      }
    },

    submit() {
      this.$emit('availableval', this.selectArr)
    },

    rowClick(row) {
      this.nowRow = row
      const index = this.selectArr.findIndex(item => item.id == row.id)
      if (index > -1) {
        this.$refs.multipleTable.toggleRowSelection(row, false)
        this.count -= 1
        this.selectArr.splice(index, 1)
      } else {
        if (this.count == this.limit) {
          return
        }
        this.$refs.multipleTable.toggleRowSelection(row, true)
        this.count += 1
        this.selectArr.push(row)
      }
    },

    getRowkey(row) {
      if (row.id) {
        return row.id
      }
    },

    selectionChange(selection, row) {
      if (this.toggleRowSelect) {
        return
      }
      const index = selection.findIndex(item => item.id == row.id)

      if (index > -1) {
        this.count += 1
        this.selectArr.push(row)
      } else {
        this.count -= 1
        this.selectArr.splice(index, 1)
      }
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.available {
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
  ::v-deep .dialogVisible {
    .el-dialog__body {
      padding: 10px 0 0 20px;
    }
    .el-table__body-wrapper {
      height: 210px;
      overflow: auto;
      @extend %scroll;
      width: 763px;
    }
    .el-table::before {
      width: 98%;
    }

    .el-table__header,
    .el-table__body,
    .el-table__footer {
      width: auto !important;
    }
    .el-table th.gutter {
      display: table-cell !important;
    }

    .el-dialog {
      height: 530px;
    }

    .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    .el-dialog__header {
      padding-top: 14px;
    }
    .dialogVisible_bottom {
      .pagination {
        text-align: center;
        margin-top: 20px;
      }
      .contain {
        display: flex;
        width: 327px;
        .type {
          margin-left: 10px;
          align-items: center;
          display: flex;
          font-size: 12px;
          color: #666666;

          img {
            width: 8px;
            height: 7px;
            margin-right: 6px;
          }

          span {
            font-size: 12px;
            color: #666666;
          }
        }
        .tu {
          width: 66px;
          height: 46px;
          background: #d8d8d8;
          margin-right: 14px;
          flex-shrink: 0;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .right {
          flex: 1;
          overflow: hidden;
          .itemtitle {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            color: #333333;
          }
          .msg {
            display: flex;
            align-items: center;
            width: 500px;
            justify-content: space-between;

            .money {
              font-size: 14px;
              color: #ff3535;
            }
            .cross_money {
              font-size: 14px;
              color: #999999;
              margin: 0 30px 0 15px;
              text-decoration: line-through;
            }
            .course_type {
              font-size: 14px;
              color: #333333;
              margin-left: 30px;
            }

            .del {
            }
          }
        }
      }
    }
  }
}
</style>
